@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Noto+Sans+Mono:wght@100..900&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html{
	scroll-behavior: smooth;
}
body {
	background-color: #faf9f6;
	min-width: 100vw;
	min-height: 100vh;
}
body * {
	margin: 0px;
	padding: 0px;
	font-family: 'Montserrat', sans-serif;
}

button {
	cursor: pointer !important;
}

header ul li {
	position: relative;
}
header ul li.active {
	opacity: 1;
}
header ul li::before {
	content: '';
	height: calc(100% - 10px);
	width: 3px;
	background-color: rgba(17, 24, 39, 0.2);
	position: absolute;
	left: -20px;
	top: 5px;
}
header ul li:first-child::before {
	width: 0px;
}

/* .hero-title{
	letter-spacing: 50px;
	font-family: "Noto Sans Mono", monospace;
	line-height: normal;
}
.hero-title span{
	font-family: "Noto Sans Mono", monospace;
}
.text-stroke {
	-webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #000000;
    -webkit-text-fill-color: #faf9f6;
	color: #faf9f6;
} */
